import React, { useEffect, useRef, useState } from "react";
import styles from "./header.module.scss";
import logoOld from "../../../assests/logo.svg";
import bar from "../../../assests/homePage/bar.svg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import CustomBtn from "../../../common/theme/button";
import { Container } from "react-bootstrap";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [mobileShow, setmobileShow] = useState(false);
  const [drop, setDrop] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleDropdown = (dropdownName) => {
    if (dropdownOpen === dropdownName) {
      setDropdownOpen(null); // Close the dropdown if it's already open
    } else {
      setDropdownOpen(dropdownName); // Open the dropdown
    }
  };

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Log to check the current value of the ref
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setMobileMenuOpen(false);

        return;
      }
      setmobileShow(false);
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div ref={ref} className={styles.headerContainer}>
      <Container>
        <div className={styles.headerWrapper}>
          <div className={styles.leftContent}>
            <NavLink to="/">
              <img src={logoOld} alt="" />
            </NavLink>
          </div>
          <div className={styles.mobileMenuButton} onClick={toggleMobileMenu}>
            <div className={styles.mobileMenuIcon}>
              <img src={bar} alt="dummy" />
            </div>
          </div>

          <div
            className={`${styles.rightContent} ${
              mobileMenuOpen ? styles.mobileMenuOpen : ""
            }`}
          >
            {!mobileMenuOpen ? (
              <ul className={styles.rightList}>
                <li>
                  <NavLink
                    to="#"
                    className={
                      location?.pathname.includes("whoWe") ? styles.active : ""
                    }
                    onMouseEnter={() => setShow(false)}
                  >
                    Who We Help
                  </NavLink>
                  <ul
                    className={`${styles.submenu} ${
                      show && styles?.hide_on_same_url
                    }`}
                    onClick={() => setShow(!show)}
                  >
                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("whoWeHelp")
                            ? styles.active
                            : ""
                        }
                        to="/whoWe/limitedCompanies"
                      >
                        {" "}
                        Limited companies
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("soleTrade")
                            ? styles.active
                            : ""
                        }
                        to="/whoWe/soleTrade"
                      >
                        {" "}
                        Sole traders
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("partnerShips")
                            ? styles.active
                            : ""
                        }
                        to="/whoWe/partnerShips"
                      >
                        Partnerships
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("contractors")
                            ? styles.active
                            : ""
                        }
                        to="/whoWe/contractors"
                      >
                        Contractors
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("freelancers")
                            ? styles.active
                            : ""
                        }
                        to="/whoWe/freelancers"
                      >
                        Freelancers
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("startups")
                            ? styles.active
                            : ""
                        }
                        to="/whoWe/startups"
                      >
                        Startups
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li>
                  <NavLink
                    to="#"
                    className={
                      location?.pathname.includes("services")
                        ? styles.active
                        : ""
                    }
                    onMouseEnter={() => setShow(false)}
                  >
                    Services
                  </NavLink>
                  <ul
                    className={`${styles.submenu} ${
                      show && styles?.hide_on_same_url
                    }`}
                    onClick={() => setShow(!show)}
                  >
                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("accounts")
                            ? styles.active
                            : ""
                        }
                        to="/services/accounts"
                      >
                        {" "}
                        Accounts
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("tax-returns")
                            ? styles.active
                            : ""
                        }
                        to="/services/tax-returns"
                      >
                        {" "}
                        Tax Returns
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("vat-returns")
                            ? styles.active
                            : ""
                        }
                        to="/services/vat-returns"
                      >
                        Vat Returns
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("bookKeeping")
                            ? styles.active
                            : ""
                        }
                        to="/services/bookKeeping"
                      >
                        Bookkeeping
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("payroll")
                            ? styles.active
                            : ""
                        }
                        to="/services/payroll"
                      >
                        Payroll
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("cis-returns")
                            ? styles.active
                            : ""
                        }
                        to="/services/cis-returns"
                      >
                        CIS Returns
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("self-assessment")
                            ? styles.active
                            : ""
                        }
                        to="/services/self-assessment"
                      >
                        Self Assessment
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li>
                  <NavLink
                    to="#"
                    className={
                      location?.pathname.includes("resources")
                        ? styles.active
                        : ""
                    }
                    onMouseEnter={() => setShow(false)}
                  >
                    Resources
                  </NavLink>
                  <ul
                    className={`${styles.submenu} ${
                      show && styles?.hide_on_same_url
                    }`}
                    onClick={() => setShow(!show)}
                  >
                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes(
                            "freebookKeeping-software"
                          )
                            ? styles.active
                            : ""
                        }
                        to="/resources/freebookKeeping-software"
                      >
                        Free bookkeeping software
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("guides-and-videos")
                            ? styles.active
                            : ""
                        }
                        to="/resources/guides-and-videos"
                      >
                        {" "}
                        Guides & videos
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("blog")
                            ? styles.active
                            : ""
                        }
                        to="/resources/blog"
                      >
                        Blog
                      </NavLink>
                    </li>

                    {/* <li>
                    <NavLink to="/resources/blog-article">
                      Blog-Article Page
                    </NavLink>
                  </li> */}
                    {/* <li>
                    <NavLink to="/resources/instant-quote">
                      Instant Quote
                    </NavLink>
                  </li> */}
                  </ul>
                </li>
                <li>
                  <NavLink
                    to="#"
                    className={
                      location?.pathname.includes("about-us")
                        ? styles.active
                        : ""
                    }
                    onMouseEnter={() => setShow(false)}
                  >
                    About Us
                  </NavLink>
                  <ul
                    className={`${styles.submenu} ${
                      show && styles?.hide_on_same_url
                    }`}
                    onClick={() => setShow(!show)}
                  >
                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("contact-us")
                            ? styles.active
                            : ""
                        }
                        to="/about-us/contact-us"
                      >
                        Contact us
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("case-studies")
                            ? styles.active
                            : ""
                        }
                        to="/about-us/case-studies"
                      >
                        Case studies
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </ul>
            ) : (
              <ul className={styles.rightList}>
                <li>
                  <div
                    onClick={() => toggleDropdown("whoWe")}
                    className={styles.main_div}
                  >
                    <NavLink
                      to="#"
                      className={
                        location?.pathname.includes("whoWe")
                          ? styles.active
                          : ""
                      }
                      onMouseEnter={() => setShow(false)}
                    >
                      Who We Help
                    </NavLink>
                    <p>
                      {dropdownOpen === "whoWe" ? (
                        <IoIosArrowUp />
                      ) : (
                        <IoIosArrowDown />
                      )}
                    </p>
                  </div>
                  <div
                    className={`${styles.submenu}  ${styles.first_time} ${
                      show && styles.hide_on_same_url
                    } ${dropdownOpen === "whoWe" && styles.drop_} `}
                    onClick={() => {
                      setShow(!show);
                    }}
                  >
                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("whoWeHelp")
                            ? styles.active
                            : ""
                        }
                        to="/whoWe/limitedCompanies"
                      >
                        {" "}
                        Limited companies
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("soleTrade")
                            ? styles.active
                            : ""
                        }
                        to="/whoWe/soleTrade"
                      >
                        {" "}
                        Sole traders
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("partnerShips")
                            ? styles.active
                            : ""
                        }
                        to="/whoWe/partnerShips"
                      >
                        Partnerships
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("contractors")
                            ? styles.active
                            : ""
                        }
                        to="/whoWe/contractors"
                      >
                        Contractors
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("freelancers")
                            ? styles.active
                            : ""
                        }
                        to="/whoWe/freelancers"
                      >
                        Freelancers
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("startups")
                            ? styles.active
                            : ""
                        }
                        to="/whoWe/startups"
                      >
                        Startups
                      </NavLink>
                    </li>
                  </div>
                </li>

                <li>
                  <div
                    onClick={() => toggleDropdown("service")}
                    className={styles.main_div}
                  >
                    <NavLink
                      to="#"
                      className={
                        location?.pathname.includes("services")
                          ? styles.active
                          : ""
                      }
                      onMouseEnter={() => setShow(false)}
                    >
                      Services
                    </NavLink>
                    <p>
                      {dropdownOpen === "service" ? (
                        <IoIosArrowUp />
                      ) : (
                        <IoIosArrowDown />
                      )}
                    </p>
                  </div>
                  <div
                    className={`${styles.submenu} ${styles.first_time} ${
                      show && styles?.hide_on_same_url
                    } ${dropdownOpen === "service" && styles.drop_} `}
                    onClick={() => {
                      setShow(!show);
                    }}
                  >
                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("accounts")
                            ? styles.active
                            : ""
                        }
                        to="/services/accounts"
                      >
                        {" "}
                        Accounts
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("tax-returns")
                            ? styles.active
                            : ""
                        }
                        to="/services/tax-returns"
                      >
                        {" "}
                        Tax Returns
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("vat-returns")
                            ? styles.active
                            : ""
                        }
                        to="/services/vat-returns"
                      >
                        Vat Returns
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("bookKeeping")
                            ? styles.active
                            : ""
                        }
                        to="/services/bookKeeping"
                      >
                        Bookkeeping
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("payroll")
                            ? styles.active
                            : ""
                        }
                        to="/services/payroll"
                      >
                        Payroll
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("cis-returns")
                            ? styles.active
                            : ""
                        }
                        to="/services/cis-returns"
                      >
                        CIS Returns
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("self-assessment")
                            ? styles.active
                            : ""
                        }
                        to="/services/self-assessment"
                      >
                        Self Assessment
                      </NavLink>
                    </li>
                  </div>
                </li>

                <li>
                  <div
                    onClick={() => toggleDropdown("resource")}
                    className={styles.main_div}
                  >
                    <NavLink
                      to="#"
                      className={
                        location?.pathname.includes("resources")
                          ? styles.active
                          : ""
                      }
                      onMouseEnter={() => setShow(false)}
                    >
                      Resources
                    </NavLink>
                    <p>
                      {dropdownOpen === "resource" ? (
                        <IoIosArrowUp />
                      ) : (
                        <IoIosArrowDown />
                      )}
                    </p>
                  </div>
                  <div
                    className={`${styles.submenu} ${styles.first_time} ${
                      show && styles?.hide_on_same_url
                    } ${dropdownOpen === "resource" && styles.drop_} `}
                    onClick={() => setShow(!show)}
                  >
                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes(
                            "freebookKeeping-software"
                          )
                            ? styles.active
                            : ""
                        }
                        to="/resources/freebookKeeping-software"
                      >
                        Free bookkeeping software
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("guides-and-videos")
                            ? styles.active
                            : ""
                        }
                        to="/resources/guides-and-videos"
                      >
                        {" "}
                        Guides & videos
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("blog")
                            ? styles.active
                            : ""
                        }
                        to="/resources/blog"
                      >
                        Blog
                      </NavLink>
                    </li>

                    {/* <li>
                    <NavLink to="/resources/blog-article">
                      Blog-Article Page
                    </NavLink>
                  </li> */}
                    {/* <li>
                    <NavLink to="/resources/instant-quote">
                      Instant Quote
                    </NavLink>
                  </li> */}
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => toggleDropdown("about")}
                    className={styles.main_div}
                  >
                    <NavLink
                      to="#"
                      className={
                        location?.pathname.includes("about-us")
                          ? styles.active
                          : ""
                      }
                      onMouseEnter={() => setShow(false)}
                    >
                      About Us
                    </NavLink>
                    <p>
                      {dropdownOpen === "about" ? (
                        <IoIosArrowUp />
                      ) : (
                        <IoIosArrowDown />
                      )}
                    </p>
                  </div>
                  <div
                    className={`${styles.submenu} ${styles.first_time} ${
                      show && styles?.hide_on_same_url
                    } ${dropdownOpen === "about" && styles.drop_} `}
                    onClick={() => setShow(!show)}
                  >
                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("contact-us")
                            ? styles.active
                            : ""
                        }
                        to="/about-us/contact-us"
                      >
                        Contact us
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={
                          location?.pathname.includes("case-studies")
                            ? styles.active
                            : ""
                        }
                        to="/about-us/case-studies"
                      >
                        Case studies
                      </NavLink>
                    </li>
                  </div>
                </li>
              </ul>
            )}

            <div className={`${styles.btns} hoverBtn`}>
              <CustomBtn
                onClick={() => {
                  navigate("/about-us/contact-us");
                }}
              >
                Request free Consultation
              </CustomBtn>
              <p>
                or call today <span>03335775 332</span>
              </p>
              {/* <CustomBtn variant="outline-primary">Call to Action</CustomBtn> */}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Header;
