import React from 'react'
import Header from '../header';
import Footer from '../footer';

interface IProps{
    children : string | JSX.Element;
}

const WebappLayout = ({children}: IProps) => {
  return (
    <>
    <Header/>{children}<Footer/>
    </>
  )
}

export default WebappLayout