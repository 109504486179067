import { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import banRigh from "../../../assests/homePage/banRigh.svg";
import banerImg from "../../../assests/homePage/banner.jpg";
import box3 from "../../../assests/homePage/box3.svg";
import box4 from "../../../assests/homePage/box4.svg";
import box5 from "../../../assests/homePage/box5.svg";
import circleBlue from "../../../assests/homePage/circleBlue.svg";
import circleLight from "../../../assests/homePage/circleLight.svg";
import blueLine from "../../../assests/homePage/lineBlue.svg";
import lineBluelIght from "../../../assests/homePage/lineBluelIght.svg";
import lineGrays from "../../../assests/homePage/lineGray.svg";
import lineWhite from "../../../assests/homePage/lineWhite.svg";
import location from "../../../assests/homePage/location.svg";
import press from "../../../assests/homePage/press1.png";
import press2 from "../../../assests/homePage/press2.png";
import press3 from "../../../assests/homePage/press3.png";
import press4 from "../../../assests/homePage/press4.png";
import ship from "../../../assests/homePage/ship.svg";
import ship1 from "../../../assests/homePage/ship1.svg";
import ship2 from "../../../assests/homePage/ship2.svg";
import user from "../../../assests/homePage/user.svg";
import whatyouget1 from "../../../assests/homePage/whatyouget1.svg";
import whatyouget2 from "../../../assests/homePage/whatyouget2.svg";
import whatyouget3 from "../../../assests/homePage/whatyouget3.svg";
import whiteCrcle from "../../../assests/homePage/whiteCrcle.svg";
import whodowe1 from "../../../assests/homePage/whodowe1.png";
import whodowe2 from "../../../assests/homePage/whodowe2.png";
import whodowe3 from "../../../assests/homePage/whodowe3.svg";
import hibooks1 from "../../../assests/homePage/hibooks1.svg";
import hibooks2 from "../../../assests/homePage/hibooks2.svg";
import hibooks3 from "../../../assests/homePage/hibooks3.svg";
import hibooks4 from "../../../assests/homePage/hibooks4.svg";
import hibooks5 from "../../../assests/homePage/hibooks5.svg";
import hibooks6 from "../../../assests/homePage/hibooks6.svg";
import connect1 from "../../../assests/homePage/connect1.png";
import connect2 from "../../../assests/homePage/connect2.svg";
import connect3 from "../../../assests/homePage/connect3.svg";
import connect4 from "../../../assests/homePage/connect4.svg";
import connect5 from "../../../assests/homePage/connect5.png";
import connect6 from "../../../assests/homePage/connect6.png";
import accounting1 from "../../../assests/homePage/accounting1.jpg";
import accounting2 from "../../../assests/homePage/accounting2.jpg";
import accounting3 from "../../../assests/homePage/accounting3.jpg";
import accounting4 from "../../../assests/homePage/accounting4.jpg";
import accounting5 from "../../../assests/homePage/accounting5.jpg";
import accounting6 from "../../../assests/homePage/accounting6.jpg";
import serviceFeature1 from "../../../assests/homePage/serviceFeature1.svg";
import serviceFeature2 from "../../../assests/homePage/serviceFeature2.svg";
import serviceFeature3 from "../../../assests/homePage/serviceFeature3.svg";
import serviceFeature4 from "../../../assests/homePage/serviceFeature4.svg";
import serviceFeature5 from "../../../assests/homePage/serviceFeature5.svg";
import serviceFeature6 from "../../../assests/homePage/serviceFeature6.svg";
import WebappLayout from "../../../components/webapp/webappLayout";
import WhoDoWe from "../../../components/webapp/whoDoWe";
import styles from "./home.module.scss";
import { Link } from "react-router-dom";
import CustomBtn from "../../../common/theme/button";
import HotPress from "../../../components/webapp/hotPress";
import PatnerShip from "../../../components/webapp/patnerShipSection";
import Quote from "../../../components/webapp/quote/Quote";
import ReviweSection from "../../../components/webapp/reviweSection";
import useFetch from "../../../hooks/useFetch";
import HiBooks from "../../../components/webapp/hiBooks";
import AccountingFor from "../../../components/webapp/accountingFor";
import SoftwareServiceFeature from "../../../components/webapp/softwareServiceFeature";

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3, // Display only 3 slides at a time
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1024, // Adjust for screens wider than 1024px
      settings: {
        slidesToShow: 3, // Display 3 slides at a time on screens wider than 1024px
        slidesToScroll: 2, // Scroll 2 slides at a time on screens wider than 1024px
      },
    },
    {
      breakpoint: 768, // Adjust for screens wider than 768px
      settings: {
        slidesToShow: 1, // Display 2 slides at a time on screens wider than 768px
        slidesToScroll: 1, // Scroll 1 slide at a time on screens wider than 768px
        infinite: false,
        dots: true,
      },
    },
    {
      breakpoint: 480, // Adjust for screens wider than 480px
      settings: {
        slidesToShow: 1, // Display 1 slide at a time on screens wider than 480px
        slidesToScroll: 1, // Scroll 1 slide at a time on screens wider than 480px
        dots: true,
      },
    },
  ],
};

const sliderData = [
  {
    imgSrc: whatyouget1,
    // imgSrcone: Ornaments,
    text: {
      title: "Unlimited Expert Advice",
      description:
        "Recieve tailored guidance from our team of qualified chartered accountants, ensuring you make informed financial decisions at every step.",
    },
  },
  {
    imgSrc: whatyouget2,
    // imgSrcone: Ornaments,
    text: {
      title: "Free Access to Our Software",
      description:
        "Enjoy full access to our user-friendly software, allowing you to effortlessly generate invoices, scan receipts and bills for automatic data extraction, and access a variety of detailed reports.",
    },
  },
  {
    imgSrc: whatyouget3,
    // imgSrcone: Ornaments,
    text: {
      title: "Affordable Pricing",
      description:
        "Benefit from our technology-driven approach that reduces accounting costs, allowing us to pass those savings directly to you with competitive, transparent pricing.",
    },
  },
  // {
  //   imgSrc: slide4,
  //   imgSrcone: Ornaments,
  //   text: {
  //     title: "Flexibility",
  //     description:
  //       "Sometimes you won’t want a particular service. And sometimes you want the whole caboodle. That's fine - simply pick and choose from a full range of financial and business services that are always flexible with your needs.",
  //   },
  // },
  // Add more slider items as needed
];

const data = [
  {
    title: "Sole Traders",
    price: (
      <p>
        From <span style={{ fontWeight: 700 }}>£35.40</span> per month
      </p>
    ),
    description:
      "Simplicity and complete accounting support for an unbeatable price",
    buttonText: "Find out more",
    onclick: <Link to="/whoWe/soleTrade" />,
    logo: whodowe1,
  },
  {
    title: "Limited Companies",
    price: (
      <p>
        From <span style={{ fontWeight: 700 }}>£60.70</span> per month
      </p>
    ),
    description:
      "Expert accountancy assistance for your growing business enterprise",
    buttonText: "Find out more",
    onclick: <Link to="/whoWe/limitedCompanies" />,
    logo: whodowe2,
  },
  {
    title: "Partnerships",
    price: (
      <p>
        From <span style={{ fontWeight: 700 }}>£85.40</span> per month
      </p>
    ),
    description:
      "A friendly and comprehensive accountancy service for you and your partners",
    buttonText: "Find out more",
    onclick: <Link to="/whoWe/partnerShips" />,
    logo: whodowe3,
  },
  // Add more data objects as needed
];

const PatnerShipdata = [
  {
    title: "Get a quote",
    description:
      "Get your instant online quote to find out how much you'll be paying.",
    logo: ship,
    blueLine: blueLine,
    count: "1",
  },
  {
    title: "Register your business",
    description:
      "Fill out our simple online registration form. It’s only short, we promise.",
    logo: ship1,
    blueLine: blueLine,
    count: "2",
  },
  {
    title: "Receive a warm welcome",
    description:
      "We’ll get you set up, settled in and introduce you to your accountant.",
    logo: ship2,
    blueLine: blueLine,
    count: "3",
  },
  // Add more data objects as needed
];

const softwareServiceData = {
  softwareFeature: [
    {
      title: "Invoice with Ease",
      description:
        "Effortlessly create professional invoices, track payments, and offer flexible payment options with Skytax's intuitive tools. Automated overdue invoice reminders keep you on track effortlessly.",
      logo: hibooks1,
    },
    {
      title: "MTD-Ready VAT Management",
      description:
        "Simplify your VAT returns with our HMRC-recognised software. Easily create, track, and submit your VAT returns with just a few clicks, ensuring compliance effortlessly.",
      logo: hibooks2,
    },
    {
      title: "Seamless Banking Integration",
      description:
        "Connect your authorised bank accounts to our software for a secure, real-time overview of your business finances. Stay informed with accurate insights into your business health at all times.",
      logo: hibooks3,
    },
    {
      title: "Automated Capture and Categorisation",
      description:
        "Our software effortlessly captures data from your invoices and receipts, automatically categorises them using AI with 99.99% accuracy, eliminating manual tasks.",
      logo: hibooks4,
    },
    {
      title: "Advanced AI-Powered Bank Reconciliation",
      description:
        "Our software utilises advanced AI to automatically match transactions with unpaid documents and categorise transactions without the need for manual rules.",
      logo: hibooks5,
    },
    {
      title: "Smart Business Insights",
      description:
        "Gain real-time visibility into your business performance to make informed decisions. Access up-to-date financial statements, including balance sheets, profit & loss, and cash flow statements, effortlessly.",
      logo: hibooks6,
    },
    // Add more data objects as needed
  ],
  serviceFeature: [
    {
      title: "Expert Advice",
      description:
        "Access unlimited guidance and support from your dedicated accountant and our team via email, phone, live chat, or video call.",
      logo: serviceFeature1,
    },
    {
      title: "Bookkeeping Services",
      description:
        "Our comprehensive bookkeeping keeps your accounts current, letting you focus on business management. Certified Chartered Accountants manage details; you upload to our software.",
      logo: serviceFeature2,
    },
    {
      title: "Tax Audit for Tax Savings",
      description:
        "Our tax audits focus on maximising savings, advising on efficient strategies to minimise your biggest expense: taxes.",
      logo: serviceFeature3,
    },
    {
      title: "Personalised Service",
      description:
        "No ‘account managers’ here. When you join us, you’ll have your own dedicated accountant from one of our UK-based offices who will get to know you and your business inside out",
      logo: serviceFeature4,
    },
    {
      title: "Regular Deadline Reminders",
      description:
        "Receive automated email reminders regularly to keep you informed of upcoming account and return submission deadlines, ensuring you stay on track and never miss a deadline again.",
      logo: serviceFeature5,
    },
    {
      title: "Year End Accounts",
      description:
        "Effortlessly complete your fiscal year with expert accounting solutions, ensuring accuracy and punctuality. Benefit from error checking and explore tax-saving opportunities to enhance financial efficiency.",
      logo: serviceFeature6,
    },
    // Add more data objects as needed
  ],
};

const dataPress = [
  {
    title: "Simple",
    date: "18th July 2023",
    description:
      "Easy to use, efficient and accurate. hibooks has helped over 75,000 small business owners to become brilliant bookkeepers.",
    logo: press,
  },
  {
    title: "Tax efficiency reviews",
    date: "18th July 2023",
    description:
      "hibooks removes the stress of bookkeeping by streamlining every process, leaving you more time to focus on growing your business.",
    logo: press2,
  },
  {
    title: "Reduce the chance of penalties",
    date: "18th July 2023",
    description:
      "Live chat and email support is provided by our very own accountants and bookkeepers, so we will be guiding you every step of the way.",
    logo: press3,
  },

  {
    title: "Reduce the chance of penalties",
    date: "18th July 2023",
    description:
      "Live chat and email support is provided by our very own accountants and bookkeepers, so we will be guiding you every step of the way.",
    logo: press4,
  },
  // Add more data objects as needed
];

const dataReview = [
  {
    title: "Amy Dear",
    subHead: "",
    description:
      "I’m grateful for Skytax Accounting’s professional support. They’re dedicated to saving money with thorough financial expertise. From tax strategies to efficiency, their proactive approach has made a significant impact. Thank you for your dedication!",
    logo: user,
  },
  {
    title: "Tor Karstensen",
    subHead: "",
    description:
      "I appreciate Skytax Accounting for their solution-oriented, agile, and fast approach. They are always available when needed. Thank you for your dedication!",
    logo: user,
  },
  {
    title: "Arya Dash",
    subHead: "",
    description:
      "The best accounting and tax filing service I have used in London. I have a pretty messy account with multiple job changes, investments and pensions. Accounts direct limited have a unique human-touch to their process backed by state-of-the-art technology that makes account management and tax filing seamless. I would 100% recommend them to any business or individual.",
    logo: user,
  },
  // Add more data objects as needed
];

const accountingData = [
  {
    title: "Expert Support for Freelancers and Contractors",
    description:
      "Let Skytax handle your limited company’s admin and tax affairs, saving you time and money while staying compliant with legislation.",
    logo: accounting1,
    buttonText: "View",
    onclick: <Link to="/whoWe/limitedCompanies" />,
  },
  {
    title: "Personalised Accounting for Small Businesses",
    description:
      "At Skytax, we understand every small business is unique. Our dedicated accountants provide a tailored approach, getting to know your business inside out to deliver comprehensive accounting services designed just for you.",
    logo: accounting2,
    buttonText: "View",
    onclick: <Link to="/whoWe/limitedCompanies" />,
  },
  {
    title: "Trade Professionals",
    description:
      "Trade professionals require expert financial and accountancy guidance tailored to their unique challenges. Skytax understands the intricacies of your business, offering comprehensive tax advice, financial planning, and business support.",
    logo: accounting3,
    buttonText: "View",
    onclick: <Link to="/whoWe/soleTrade" />,
  },
  {
    title: "Healthcare Accounting",
    description:
      "Skytax provides tailored accountancy services for GP and medical practices, including expert financial advice, bookkeeping, tax planning, forecasts, and a dedicated payroll service.",
    logo: accounting4,
    buttonText: "View",
    onclick: <Link to="/whoWe/limitedCompanies" />,
  },
  {
    title: "Tech Startups",
    description:
      "Skytax delivers specialised accounting services designed for tech startups, offering expert financial advice, comprehensive bookkeeping, strategic tax planning, and essential financial forecasts.",
    logo: accounting5,
    buttonText: "View",
    onclick: <Link to="/whoWe/limitedCompanies" />,
  },
  {
    title: "CIS Accounting",
    description:
      "Skytax offers professional and reliable CIS accounting services tailored for UK contractors. From contractors to sub-contractors and suppliers, we handle the complexities of the Construction Industry Scheme (CIS) so you can focus on your business.",
    logo: accounting6,
    buttonText: "View",
    onclick: <Link to="/whoWe/soleTrade" />,
  },
  // Add more data objects as needed
];

const Home = () => {
  const [blogListApi, { response: apiResponse }] = useFetch(
    `/v1/public-blog/list`,
    {
      method: "post",
    }
  );

  const allBlogs = apiResponse ? apiResponse?.data?.list.slice(0, 4) : [];

  const [widthAll, setWidthAll] = useState<any>();

  useEffect(() => {
    blogListApi();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setWidthAll(window.innerWidth);
  }, []);

  return (
    <WebappLayout>
      <>
        <div className={styles.homePage}>
          <Container>
            <div className={styles.mainPage}>
              <Row>
                <Col className="d-flex align-items-center col-md-5" xs={12}>
                  <div className={styles.leftPage}>
                    <div className={styles.linegrayssTop}>
                      {/* <img src={lineBluelIght} alt="" /> */}
                    </div>
                    <div className={styles.provide}>
                      <h6>Digital Accounting, Powered by Smart Software</h6>
                      <h1>You live life.</h1>
                      <h1 className={styles.provide_h1}>
                        We automate your accounting.
                      </h1>
                      <p>
                        Save time, money, and reduce your tax burden with
                        Skytax. Our expert accountants and smart software make
                        your accounting effortless.
                      </p>
                      <div className={`${styles.buttons} hoverBtn `}>
                        <Link to="/about-us/contact-us">
                          <Button>Request free Consultation</Button>
                        </Link>
                        {/* <Link to="https://hibooks.com/" target="_blank">
                          <Button className={styles.actionButton}>
                            Check out hibooks
                          </Button>
                        </Link> */}
                      </div>
                    </div>
                    <div className={styles.linegrayss}>
                      {/* <img src={lineGrays} alt="" /> */}
                    </div>
                  </div>

                  <div className={`${styles.circlelight} ${styles.grow}`}>
                    <img src={circleLight} alt="" />
                  </div>
                  <div
                    className={`${styles.circlelight} ${styles.topLight} ${styles.shrink}`}
                  >
                    <img src={whiteCrcle} alt="" />
                  </div>
                </Col>

                <Col className="col-md-7" xs={12}>
                  <div className={styles.banRightLine}>
                    {/* <img src={banRigh} alt="" /> */}
                  </div>
                  <div className={styles.rightPage}>
                    <img src={banerImg} alt="" />
                  </div>
                  <div className={styles.circleblue}>
                    <img src={circleBlue} alt="" />
                  </div>
                </Col>
              </Row>
              <div className={styles.lineWhite}>
                {/* <img src={lineWhite} alt="" /> */}
              </div>
            </div>
          </Container>
        </div>

        <div className={styles.WhyChoose}>
          <Container>
            <Row>
              <div className={styles.slickSlider}>
                <h2>What you Recieve</h2>
                <p>
                  No matter which service you choose, you'll always benefit from
                  the following:
                </p>
              </div>
              <div className={`sliderCustom m-0  ${styles.slickSliderMAin} `}>
                <Slider {...settings}>
                  {sliderData.map((item, index) => (
                    <div key={index} className={styles.chosseBox}>
                      <div className={styles.chosseBoxImg}>
                        <img
                          src={item.imgSrc}
                          alt=""
                          width="80px"
                          height="80px"
                        />
                        {/* <img src={item.imgSrcone} alt="" /> */}
                      </div>
                      <div className={styles.chooseText}>
                        <h2>{item.text.title}</h2>
                        <p>{item.text.description}</p>
                      </div>
                    </div>
                  ))}
                </Slider>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Link to="/about-us/contact-us">
                    <CustomBtn className={styles.recieveBtn}>
                      Request a free Consultation
                    </CustomBtn>
                  </Link>
                </div>
              </div>
            </Row>
          </Container>
        </div>

        <div className={styles.WhoDoWe}>
          <WhoDoWe
            data={data}
            heading="Who do we help?"
            subHeading="We proudly support thousands of UK businesses, helping them thrive with our expert accounting solutions.
            "
            innerText="We also help Contractors and LLPs."
          />
        </div>

        <div className={styles.HiBooks}>
          <AccountingFor accountingData={accountingData} />
        </div>

        <div className={styles.HiBooks}>
          <SoftwareServiceFeature
            softwareFeature={softwareServiceData.softwareFeature}
            serviceFeature={softwareServiceData.serviceFeature}
            softwareHeading="Accounting Software Included"
            softwareSubHeading="You’ll receive free access to our smart, powerful online accounting platform, ensuring you have everything you need to manage your finances efficiently."
            serviceHeading="hibooks always included"
            serviceSubHeading="You'll get free access to hibooks, our smart, powerful online accounting platform."
          />
        </div>
        <div style={{ backgroundColor: "rgba(9, 84, 126, 0.05" }}>
          <Container>
            <div className={styles.connect}>
              <h3>Connect your bank account</h3>
              <div className={styles.connect_imgs}>
                <img src={connect1} alt="" height={29} width={164} />
                <img src={connect2} alt="" height={29} width={171} />
                <img src={connect3} alt="" height={40} width={118} />
                <img src={connect4} alt="" height={29} width={121} />
                <img src={connect5} alt="" height={43} width={138} />
                <img src={connect6} alt="" height={32} width={121} />
              </div>
              {/* <img src={large} alt="" /> */}
              <div className={styles.line}>
                {/* <img src={lineGray} alt="" /> */}
              </div>

              <p>+ Plus every bank you'll ever need.</p>
              <Link to="/about-us/contact-us">
                <CustomBtn
                  style={{
                    height: "54px",
                    padding: "0px 30px",
                    fontWeight: 800,
                    fontSize: "14px",
                  }}
                >
                  Request a free consultation
                </CustomBtn>
              </Link>
            </div>
          </Container>
        </div>

        <Container>
          <div className={styles.longTerm}>
            <h1>HMRC approved & trusted</h1>
            <h1 className={styles.longTerm_h1}>
              chartered certified accountants
            </h1>
            {/* <img src={large} alt="" /> */}
            <div className={styles.line}>
              {/* <img src={lineGray} alt="" /> */}
            </div>

            <p>
              We’ll handle your business finances competently and efficiently.
              Our accountants and support teams hold a diverse range of ​ <br />{" "}
              qualifications and accreditations from professional bodies like
              the AAT and ACCA.
            </p>
          </div>
        </Container>
        <div className={styles.HiBooks}>
          <ReviweSection dataReview={dataReview} height={286} />
        </div>

        <div className={styles.HiBooks}>
          <PatnerShip
            PatnerShipdata={PatnerShipdata}
            heading="Getting started"
            subHeading="Get started on your road to stress-free, streamlined finances in just three simple steps:"
          />
        </div>

        {/* <div className={styles.MapSection}>
          <Container>
            <Row>
              <Col className="col-md-12 mb-4 pb-3" xs={12}>
                <div className={styles.officeHeading}>
                  <h2>Where we work</h2>
                  <p>
                    Our head office is smack bang in the centre of London’s
                    historical Docklands, but our <br /> Skytax accountants live
                    and work all over the UK.
                  </p>
                </div>
              </Col>

              <Col className="col-md-6" xs={12}>
                <div className={styles.maps}>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.199050040284!2d-0.019642000000004036!3d51.504933000000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487602cdae6dbaed%3A0x6ec7d6d13c8a5dbc!2sOne%20Canada%20Square!5e0!3m2!1sen!2suk!4v1697667597392!5m2!1sen!2suk"
                    width="100%"
                    height="450"
                    loading="lazy"
                    title="lazy"
                  ></iframe>
                </div>
              </Col>
              <Col className="col-md-6" xs={12}>
                <div className={styles.officeText}>
                  <img src={location} alt="" />
                  <div className={styles.innerText}>
                    <h3>London Office</h3>
                    <p>37th Floor, One Canada Square</p>
                    <p>Canary Wharf</p>
                    <p>London E14 5AA</p>
                    <p>Email: hello@skytax.co.uk</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div> */}

        <div className={styles.HiBooks}>
          <HotPress
            allBlogs={allBlogs}
            dataPress={dataPress}
            heading="Explore our blog"
            subHeading="Practical solutions, expert advice and more."
          />
        </div>

        {/* <div className={styles.HiBooks}>
          <Quote
            heading="Great support for your business, fast."
            subheading="Get a quote"
            headingOne="Your business is different. It has unique challenges and strengths, and that’s why our services aren’t one-size-fits-all."
            headingTwo="Perhaps just one of our services is right for you - or perhaps the whole package. But whatever you choose, you’ll find our monthly fee is transparent, fair, clear and affordable. No hidden extras. No surprises."
            headingthree="We hope you like what you see so far.  If you’re curious about how much it’ll cost, don’t wait, get a quote for our Tax Return  services now. Simply complete and submit the form."
            id={undefined}
          />
        </div> */}
      </>
    </WebappLayout>
  );
};

export default Home;
