import "./scss/App.scss";
import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { Route, Navigate, Routes, useNavigate } from "react-router-dom";
import Home from "./pages/webApp/home";
import ContactUs from "./pages/webApp/aboutUs/contactUs";
import MainCaseStudies from "./pages/webApp/aboutUs/caseStudies/mainCasestudies";
import InstantQuote from "./pages/webApp/resources/Instant Quote";
import SoleTrade from "./pages/webApp/whoWeHelpPage/soleTrade";
import PatnerShips from "./pages/webApp/whoWeHelpPage/patnerShips";
import Contractors from "./pages/webApp/whoWeHelpPage/contractors";
import Freelancers from "./pages/webApp/whoWeHelpPage/freelancers";
import Startups from "./pages/webApp/whoWeHelpPage/startups";
import WhoWeHelp from "./pages/webApp/whoWeHelpPage/whowehelp";
import Accounts from "./pages/webApp/services/accounts";
import BookKeepingSoftware from "./pages/webApp/resources/freeBookKeepingSoftware";
import TaxReturns from "./pages/webApp/services/taxReturns";
import GuidesAndVideos from "./pages/webApp/resources/guidesAndVideos";
import Blog from "./pages/webApp/resources/blog";
import BlogArticle from "./pages/webApp/resources/blogArticlePage";
import VatReturns from "./pages/webApp/services/vatReturns";
import BookKeeping from "./pages/webApp/services/bookKeeping";
import Payroll from "./pages/webApp/services/payroll";
import CisReturns from "./pages/webApp/services/cisReturns";
import SelftAssinments from "./pages/webApp/services/selftAssinments";
import Login from "./pages/admin/login";
import Blogs from "./pages/admin/blog";
import Category from "./pages/admin/category";

import AddEditBlog from "./pages/admin/blog/addEditBlog";
import Dashboard from "./pages/admin/dashboard/Dashboard";
import AddEditCategory from "./pages/admin/category/addEditCategory";
import ForgotPassword from "./pages/admin/forgotPassword";
import ChangePassword from "./pages/admin/changePassword";
import BlogDetails from "./pages/admin/blog/blogDetails";
import ResetPassword from "./pages/admin/resetPassword";
import Guides from "./pages/admin/guides";
import AddEditGuides from "./pages/admin/guides/addEditGuides";
import AddEditVideos from "./pages/admin/videos/addEditVideos";
import Videos from "./pages/admin/videos";
import Users from "./pages/admin/users";
import AddEditUsers from "./pages/admin/users/addEditUsers";
import UsersDetails from "./pages/admin/users/usersDetails";
import EditProfile from "./pages/admin/editProfile";
import { useDispatch } from "react-redux";
import { setUserData } from "./redux/common";

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // const authToken = localStorage.getItem('authToken');
    // const isAdminPath = window.location.pathname.startsWith('/admin');
    // if (isAdminPath && authToken) {
    //   navigate('/admin/dashboard');
    // } else if (isAdminPath && !authToken) {
    //   navigate('/admin/login');
    // }

    const authToken = localStorage.getItem("authToken");
    const isAdminPath = window.location.pathname.startsWith("/admin");
    if (authToken) {
      // navigate('/admin/dashboard');
    } else if (!authToken && isAdminPath) {
      navigate("/admin/login");
    }
    const data: any = localStorage.getItem("userData") ?? "";

    if (!!data) {
      dispatch(setUserData(JSON.parse(data)));
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const crispChatbox: any = document.querySelector(".cc-nsge");
    if (crispChatbox) {
      crispChatbox.classList.add("custom-crisp-chatbox");
      crispChatbox.style.position = "fixed";
      crispChatbox.style.bottom = "80px";
    }
    return () => {
      // Clean up any resources if necessary
    };
  }, []);
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us/contact-us" element={<ContactUs />} />
        <Route path="/about-us/case-studies" element={<MainCaseStudies />} />
        <Route path="/whoWe/limitedCompanies" element={<WhoWeHelp />} />
        <Route path="/whoWe/soleTrade" element={<SoleTrade />} />
        <Route path="/whoWe/partnerShips" element={<PatnerShips />} />
        <Route path="/whoWe/contractors" element={<Contractors />} />
        <Route path="/whoWe/freelancers" element={<Freelancers />} />
        <Route path="/whoWe/startups" element={<Startups />} />
        <Route path="/services/accounts" element={<Accounts />} />
        <Route path="/services/tax-returns" element={<TaxReturns />} />
        <Route path="/services/vat-returns" element={<VatReturns />} />
        <Route path="/services/bookKeeping" element={<BookKeeping />} />
        <Route path="/services/payroll" element={<Payroll />} />
        <Route path="/services/cis-returns" element={<CisReturns />} />
        <Route path="/services/self-assessment" element={<SelftAssinments />} />

        <Route
          path="/resources/freebookKeeping-software"
          element={<BookKeepingSoftware />}
        />
        <Route
          path="/resources/guides-and-videos"
          element={<GuidesAndVideos />}
        />
        <Route path="/resources/blog" element={<Blog />} />
        <Route path="/resources/blog-article/:id" element={<BlogArticle />} />
        <Route path="/resources/instant-quote" element={<InstantQuote />} />

        <Route path="/admin" element={<Navigate to="/admin/login" />} />

        <Route path="/admin/login" element={<Login />} />
        <Route path="/admin/forgotPassword" element={<ForgotPassword />} />
        <Route path="/admin/changePassword" element={<ChangePassword />} />
        <Route path="/admin/reset-password" element={<ResetPassword />} />
        <Route path="/admin/editProfile" element={<EditProfile />} />

        <Route path="/admin/category" element={<Category />} />
        <Route path="/admin/category/add" element={<AddEditCategory />} />
        <Route path="/admin/category/edit/:id" element={<AddEditCategory />} />

        <Route path="/admin/guides" element={<Guides />} />
        <Route path="/admin/guides/add" element={<AddEditGuides />} />
        <Route path="/admin/guides/edit/:id" element={<AddEditGuides />} />

        <Route path="/admin/videos" element={<Videos />} />
        <Route path="/admin/videos/add" element={<AddEditVideos />} />
        <Route path="/admin/videos/edit/:id" element={<AddEditVideos />} />

        <Route path="/admin/blog" element={<Blogs />} />
        <Route path="/admin/blog/add" element={<AddEditBlog />} />
        <Route path="/admin/blog/edit/:id" element={<AddEditBlog />} />
        <Route path="/admin/blog/details/:id" element={<BlogDetails />} />

        <Route path="/admin/users" element={<Users />} />
        <Route path="/admin/users/add" element={<AddEditUsers />} />
        <Route path="/admin/users/edit/:id" element={<AddEditUsers />} />
        <Route path="/admin/users/details/:id" element={<UsersDetails />} />

        <Route path="/admin/dashboard" element={<Dashboard />} />
      </Routes>
    </div>
  );
}

export default App;
